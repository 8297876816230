import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
  BlockTitle, StyledCampDetails, TitleBlock,
  Block,
  HalfBlock,
  CampText,
  CampTitle,
  CampSubtitle,
  LargeText,
  IconBlock,
  IconBlockIcon,
  IconBlockLabel,
  InnerWrapper,
  LargeHyphen,
  IconBlocksWrapper,
  DetailsWrapper,
} from './styles';

export const CampDetails = ({
  className,
  title,
  additionalBlockTitle,
  additionalBlockCopy,
  agesCopy,
  weeksCopy,
  relationships,
}) => {
  const camps = relationships?.camps || [];
  const features = relationships?.features || [];
  const skillLevel = relationships?.skillLevel || [];

  return (
    <StyledCampDetails className={className}>
      <TitleBlock className="detail-1">{title}</TitleBlock>
      <HalfBlock className={classNames('detail-2', { 'missing-sibling': !camps?.[1] })}>
        <BlockTitle>Full Week</BlockTitle>
        <CampText>
          <CampTitle>{camps?.[0]?.title}</CampTitle>
          <CampSubtitle>
            {ReactHtmlParser(camps?.[0]?.subheader?.value)}
          </CampSubtitle>
        </CampText>
      </HalfBlock>
      <HalfBlock className={classNames('detail-3')}>
        {(camps?.[1]) && (
          <>
            <BlockTitle>Half Week</BlockTitle>
            <CampText>
              <CampTitle>{camps?.[1]?.title}</CampTitle>
              <CampSubtitle>
                {ReactHtmlParser(camps?.[1]?.subheader?.value)}
              </CampSubtitle>
            </CampText>
          </>
        )}
      </HalfBlock>
      <Block className="detail-4">
        <BlockTitle>Ages</BlockTitle>
        <LargeText>{ReactHtmlParser(agesCopy?.value)}</LargeText>
      </Block>
      <Block className="detail-5">
        <BlockTitle>Skill Level</BlockTitle>
        <InnerWrapper>
          <IconBlock>
            <IconBlockIcon name={skillLevel?.[0]?.iconName} />
            <IconBlockLabel>{skillLevel?.[0]?.title}</IconBlockLabel>
          </IconBlock>
          <LargeHyphen />
          <IconBlock>
            <IconBlockIcon name={skillLevel?.[1]?.iconName} />
            <IconBlockLabel>{skillLevel?.[1]?.title}</IconBlockLabel>
          </IconBlock>
        </InnerWrapper>
      </Block>
      <Block className="detail-6">
        <BlockTitle>Weeks Offered</BlockTitle>
        <LargeText>
          {ReactHtmlParser(weeksCopy?.value)}
        </LargeText>
      </Block>
      <Block className="detail-7">
        <BlockTitle>Key Features</BlockTitle>
        <IconBlocksWrapper>
          {features.map((i) => (
            <IconBlock>
              <IconBlockIcon name={i?.iconName} />
              <IconBlockLabel>{i?.title}</IconBlockLabel>
            </IconBlock>
          ))}
        </IconBlocksWrapper>
      </Block>
      <Block className="detail-8">
        <BlockTitle>{additionalBlockTitle}</BlockTitle>
        <DetailsWrapper>
          {ReactHtmlParser(additionalBlockCopy?.value)}
        </DetailsWrapper>
      </Block>
    </StyledCampDetails>
  );
};

CampDetails.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  additionalBlockTitle: PropTypes.string,
  additionalBlockCopy: PropTypes.shape({
    value: PropTypes.string,
  }),
  agesCopy: PropTypes.string,
  weeksCopy: PropTypes.string,
  relationships: PropTypes.shape({
    camps: PropTypes.arrayOf(PropTypes.shape({})),
    features: PropTypes.arrayOf(PropTypes.shape({})),
    gender: PropTypes.arrayOf(PropTypes.shape({})),
    skillLevel: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

CampDetails.defaultProps = {
  className: '',
  title: null,
  additionalBlockTitle: null,
  additionalBlockCopy: null,
  agesCopy: null,
  weeksCopy: null,
  relationships: null,
};
